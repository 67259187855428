/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Footy Coins",
  title: "Welcome to Footy Coins",
  subTitle: emoji(
    "FootyFunnies is a community-driven meme token that celebrates the lighter side of soccer. Users can share and vote on the funniest soccer-related memes, participate in meme contests, and earn FootyCoins (FTC) for their creativity."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://twitter.com/footycointoken?s=09",
  linkedin: "https://t.me/+VCEI_e2YcKcxOGRh",
  gmail: "admin@footycoins.com",
  gitlab: "https://gitlab.com/saadpasta",
  facebook: "https://www.facebook.com/saad.pasta7",
  medium: "https://medium.com/@saadpasta",
  stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Features of Footy",
  subTitle: "Revolutionizing the World of Sports Tokens In the ever-evolving landscape of cryptocurrency and blockchain technology, innovative applications continue to emerge, each seeking to disrupt traditional industries.",
  skills: [
   
    emoji(
      "⚡ Burning Mechanism: Implement a Red Card burning mechanism, where a percentage of FTC is burned each time a meme receives a particularly high number of votes."
    ),
    emoji(
      "⚡Fan Engagement: Footy Coins enables fans to engage with their favorite clubs and players in ways never before possible. Through the token's platform, fans can vote on club decisions, participate in exclusive events, access unique merchandise, and even interact directly with players through virtual meet-and-greets or Q&A sessions."
    ),
    emoji(
      "⚡Financial Incentives: By holding Footy Coins, fans gain access to various financial incentives, such as discounts on merchandise, tickets, or exclusive club memberships. Additionally, fans may receive rewards for their loyalty, such as dividends or profit-sharing from club revenue generated through token sales or partnerships."
    ),
    emoji(
      "⚡Tokenized Ownership: Footy Coins introduces the concept of tokenized ownership, allowing fans to own fractional shares of their favorite clubs or players. This democratizes the ownership structure of football clubs, enabling fans from around the world to invest in the success of their beloved teams."
    ),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Binace Feed",
      logo: require("./assets/images/binance.jpg"),
      subHeader: "",
      duration: "",
      desc: "",
      descBullets: [
        
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "",
      company: "MEXC",
      companylogo: require("./assets/images/mexc.png"),
      date: "",
      desc: "",
      descBullets: [
      ]
    },
    {
      role: "",
      company: "GATE",
      companylogo: require("./assets/images/gate.png"),
      date: "",
      desc: ""
    },
    
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Listing Partners",
  subtitle: "We have listing plans for..",
  projects: [
    {
      image: require("./assets/images/mexc.png"),
      projectName: "MEXC",
      projectDesc: "",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://mexc.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/gate.png"),
      projectName: "GATE",
      projectDesc: "",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://gate.io/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Tokenomics "),
  subtitle:
    "Token Distribution",

  achievementsCards: [
    {
      title: "",
      subtitle:
        "",
      image: require("./assets/images/tokenomics.png"),
      imageAlt: "",
      footerLink: [
       
      ]
    },
   
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Roadmap",
  subtitle:
    "Roadmap for Footy Coins Project",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "",
      title: "Partnership and Community Building",
      description:
        "Forge strategic partnerships with football clubs, leagues, sponsors, and other stakeholders to expand the project's reach and utility."
    },
    {
      url: "",
      title: "Launch and Initial Adoption",
      description:
        "Onboard football clubs, players, and fans onto the platform, offering incentives such as exclusive rewards, discounts, and tokenized ownership opportunities."
    },
    {
      url: "",
      title: "Expansion and Growth",
      description:
        "Onboard football clubs, players, and fans onto the platform, offering incentives such as exclusive rewards, discounts, and tokenized ownership opportunities."
    },
    {
      url: "",
      title: "Long-Term Sustainability and Evolution",
      description:
        "Establish a governance mechanism to enable community-driven decision-making and ensure the project's long-term sustainability and decentralization."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact US"),
  subtitle:
    "We have a dedicated support team",
  number: "",
  email_address: "admin@footycoins.com"
};

// Twitter Section

const twitterDetails = {
  userName: "footycointoken", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
